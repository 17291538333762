<template>
    <section class="h-100">
        <div class="row mx-0 p-2 py-3 px-xl-3 px-lg-0 px-md-0 px-sm-0 px-0 align-items-center border-bottom">
            <template>
                <button v-show="id_pedido" type="button" class="btn-action border mr-2" @click="imprimir">
                    <i class="icon-printer f-20" />
                </button>
                <div class="col tres-puntos pl-0">
                    <p v-show="pedido.estado === 2" class="tres-puntos">
                        <b class="text-general">
                            ID. {{ pedido.id }}
                        </b>Pedido Confirmado
                    </p>
                    <p v-show="pedido.estado === 201" class="tres-puntos">
                        <b class="text-general">
                            ID. {{ pedido.id }}
                        </b>Pedido En Alistamiento
                    </p>
                    <p v-show="pedido.estado === 202" class="tres-puntos">
                        <b class="text-general">
                            ID. {{ pedido.id }}
                        </b>Pedido En Empacado
                    </p>
                    <p v-show="pedido.estado === 3" class="tres-puntos">
                        <b class="text-general">
                            ID. {{ pedido.id }}
                        </b>Pedido Enviado
                    </p>
                    <p v-show="pedido.estado === 4" class="tres-puntos">
                        <b class="text-general">
                            ID. {{ pedido.id }}
                        </b>Pedido Entregado
                    </p>
                    <p v-show="pedido.estado === 27" class="tres-puntos">
                        <b class="text-general">
                            ID. {{ pedido.id }}
                        </b>Pedido Cancelado Por Admin
                    </p>
                    <p v-show="pedido.estado === 25" class="tres-puntos">
                        <b class="text-general">
                            ID. {{ pedido.id }}
                        </b>Pedido Rechazado
                    </p>
                    <p v-show="pedido.estado === 26" class="tres-puntos">
                        <b class="text-general">
                            ID. {{ pedido.id }}
                        </b>Pedido Cancelado Por Tendero
                    </p>
                    <p v-show="pedido.estado === 1" class="tres-puntos">
                        <b class="text-general">
                            ID. {{ pedido.id }}
                        </b>Pedido pendiente de confirmacion
                    </p>
                    <p v-show="pedido.estado === 31" class="tres-puntos">
                        <b class="text-general">
                            ID. {{ pedido.id }}
                        </b>Pedido con reclamo en curso
                    </p>
                    <p v-show="pedido.estado === 32" class="tres-puntos">
                        <b class="text-general">
                            ID. {{ pedido.id }}
                        </b>Pedido con reclamo finalizado
                    </p>
                </div>

                <div v-if="pedido.calificacion === null && (pedido.estado === 4 || pedido.estado == 31 || pedido.estado == 32)" class="cr-pointer ml-5" @click="open_calificar_pedido">
                    <span class="ml-3">Pedido sin calificar</span>
                    <i class="icon-star text-muted mx-2" />
                </div>
                <div v-else-if="pedido.calificacion !== null && (pedido.estado === 4 || pedido.estado == 31 || pedido.estado == 32)" class="cr-pointer ml-5" @click="open_calificaciones">
                    <span class="ml-3">{{ redondearNumero(pedido.calificacion,1) }}</span>
                    <i class="icon-star text-warning mx-2" />
                </div>

                <div class="d-middle ml-auto">
                    <div v-show="pedido.estado === 27" class="d-middle border-orange px-1 br-10 f-15">
                        <i class="text-danger f-18 icon-cancel-circled-outline" />
                        <p>Cancelado</p>
                        <p>({{ pedido.updated_at | helper-fecha('DD/MM/Y - hh:mm a') }})</p>
                    </div>
                    <div v-show="pedido.estado === 26" class="d-middle border-orange px-1 br-10 f-15">
                        <i class="text-danger f-18 icon-account-remove-outline" />
                        <p>Cancelado</p>
                        <p>({{ pedido.updated_at | helper-fecha('DD/MM/Y - hh:mm a') }})</p>
                    </div>
                    <div v-show="pedido.estado === 25" class="d-middle border-orange px-1 br-10 f-15">
                        <i class="text-danger f-18 icon-playlist-remove" />
                        <p>Rechazado</p>
                        <p>({{ pedido.updated_at | helper-fecha('DD/MM/Y - hh:mm a') }})</p>
                    </div>
                    <div v-show="[1,2,201,202].indexOf(pedido.estado) > -1" type="button" class="btn-grey px-2" @click="modal_cancelar_pedido">
                        Cancelar Pedido
                    </div>
                    <div v-show="pedido.estado == 3" type="button" class="btn-grey px-2" @click="$refs.modalAdvertencia.toggle()">
                        Cancelar Pedido
                    </div>
                    <div v-show="[4].indexOf(pedido.estado) > -1" type="button" class="btn-general px-2" @click="modal_reclamar">
                        Iniciar Reclamo
                    </div>
                    <el-tooltip v-if="pedido.id" class="item" effect="light" content="Chat del pedido" placement="bottom">
                        <el-badge :value="pedido.cant_mensajes" class="item mx-2 mr-4">
                            <i class="icon-message-text-outline f-18 cr-pointer text-general2 hover-icons" @click="verChat" />
                        </el-badge>
                    </el-tooltip>
                </div>
            </template>
        </div>
        <div class="row mx-0 container-cols">
            <div class="col-7 px-0 h-100 border-right">
                <div v-show="id_pedido !== null" class="mx-5 my-2">
                    <el-input v-model="buscar" size="medium" clearable placeholder="Buscar producto" class="br-20" />
                </div>
                <div class="p-3 f-15 overflow-auto custom-scroll" style="height:calc(100% - 50px);">
                    <div class="row mx-0 justify-center">
                        <card-producto-pedido
                        v-for="(prod, idx) in productos.filter(data => !buscar || data.producto.nombre.toLowerCase().includes(buscar.toLowerCase()))" :key="`prod-${idx}`"
                        :info="prod"
                        :diseno="prod.data_promocion"
                        class="cr-pointer"
                        @accion="modalProducto"
                        />
                    </div>

                    <contentLoader v-show="loading" />

                    <div v-show="id_pedido === null" class="row h-100 mx-0 align-items-center justify-content-center">
                        <div class="text-center f-15 f-600 d-middle-center flex-column" style="width:225px;">
                            <div class="d-middle-center bg-whitesmoke br-5" style="width:115px;height:115px;">
                                <img height="80" src="/img/no-imagen/click.svg" alt="" />
                            </div>
                            <p>Selecciona uno de los pedidos activos del panel de la izquierda.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-5 px-0 h-100">
                <div class="row mx-0 border-bottom pt-2">
                    <div
                    class="col pt-0 d-middle-center cr-pointer"
                    :class="tab=='detalle'? 'border-b-red text-general-red':''"
                    @click="tab='detalle'"
                    >
                        Detalle
                    </div>
                    <div
                    v-if="!_.isEmpty(pedido) && (pedido.pago_estado != 1 || pedido.transferencia == 1)"
                    class="col pt-0 d-middle-center cr-pointer"
                    :class="tab=='pago'? 'border-b-red text-general-red':''"
                    @click="tab='pago'"
                    >
                        Pago
                    </div>
                    <div
                    v-show="pedido.estado === 31 || pedido.estado === 32"
                    class="col pt-0 d-middle-center cr-pointer"
                    :class="tab=='reclamo'? 'border-b-red text-general-red':''"
                    @click="tab='reclamo'"
                    >
                        Reclamo
                    </div>
                </div>
                <div class="col px-0 h-100 overflow-auto custom-scroll">
                    <detalle-historial v-show="tab === 'detalle' && id_pedido" :pedido="pedido" />
                    <pago v-show="tab === 'pago'" :pedido="pedido" :vista="1" />
                    <chat-reclamo v-if="tab === 'reclamo' && id_pedido" @actualizar="$emit('update')" />
                </div>
            </div>
        </div>

        <modalDetalleProd ref="modalDetalleProd" />
        <modalCancelarPedido ref="modalCancelarPedido" @update="$emit('update')" />
        <modalCancelarPedidoDirecto ref="modalCancelarPedidoDirecto" @update="$emit('update')" />
        <modalCalificacionesPedidoAdmin ref="modalCalificacionesPedidoAdmin" />
        <modalCalificarPedido ref="modalCalificarPedido" />
        <modalReclamo ref="modalReclamo" @update="$emit('update')" />
        <modalChat ref="modalChat" :pedido="pedido" :leechero="cliente" @actualizarIcono="actualizarIconoChat" />
        <modal-imprimir ref="imprimirRecibo" />

        <modal ref="modalAdvertencia" titulo="¡Aguarda!" icon="close" center-title cerrar no-aceptar no-cancelar>
            <div class="row mx-0 justify-center">
                <div class="col-12 text-general text-center f-15 mt-3">
                    <p class="f-500 f-16 mb-3">¡Este pedido ya esta en la ruta de entrega!</p>
                    Para poder cancelar por favor comunicate con el Centro de Distribución.
                </div>
            </div>
        </modal>
    </section>
</template>

<script>
import PdfPedidos from '~/services/pdf/pdf'
import {mapGetters} from 'vuex'
export default {
    // sockets: {
    //     pedidos_reclamo(obj){
    //         let { id } = JSON.parse(obj)
    //         this.$store.dispatch('pedidos/pedidos_historial_tendero/set_data')
    //         // if (this.$route.params.id_pedido == id){
    //         //     this.listar_productos()
    //         //     this.buscar = ''
    //         // }else{
    //         // }
    //     },
    // },
    components: {
        detalleHistorial: () => import('./detalleHistorial'),
        chatReclamo: () => import('./chatReclamo'),
        pago: () => import('./pago.vue'),
        modalDetalleProd: () => import('../../pedidos/partials/modalDetalleProducto'),
        modalCancelarPedido: () => import('../partials/modalCancelarPedido'),
        modalCancelarPedidoDirecto: () => import('../partials/modalCancelarPedidoDirecto'),
        cardProductoPedido: () => import('../partials/card-producto-pedido'),
        modalCalificacionesPedidoAdmin: () => import('../../pedidos/partials/modalCalificacionPedido'),
        modalCalificarPedido: () => import('../partials/modalCalificarPedido'),
        modalReclamo: () => import('../partials/modalReclamo'),
    },
    data(){
        return {
            tab:'detalle',
            editar: false,
            verDetalle: 0,
            estadoPedido: { estado: 'Reportado', icono: 'icon-cancel-circled-outline' },
            loading:false,
            buscar:''

        }
    },
    computed:{
        ...mapGetters({
            id_pedido: 'pedidos/pedidos_historial_tendero/id_pedido',
            cliente: 'pedidos/pedidos_historial_tendero/cliente',
            productos: 'pedidos/pedidos_historial_tendero/productos',
            pedido: 'pedidos/pedidos_historial_tendero/pedido',
            pedidos: 'pedidos/pedidos_historial_tendero/pedidos', // solo para actualizar icono chat
        }),
    },
    watch:{
        id_pedido(val){
            this.tab = 'detalle'
            if(val){
                this.listar_productos()
                this.buscar = ''
            }else{
                this.$store.dispatch('pedidos/pedidos_historial_tendero/set_data')
            }
        }
    },
    methods: {
        async listar_productos(){
            try {
                this.loading = true
                await this.$store.dispatch('pedidos/pedidos_historial_tendero/pedidos_productos',this.id_pedido)
                this.$store.dispatch('pedidos/pedidos_historial_tendero/pedidos_resumenes', this.id_pedido)
                this.$store.dispatch('pedidos/pedidos_historial_tendero/pedidos_historial',this.id_pedido)
            } catch (e){
                this.error_catch(e)
            } finally{
                this.loading = false
            }
        },
        modalProducto(prod){
            this.$refs.modalDetalleProd.detalle_producto_tendero(prod,this.pedido.idm_moneda) // modal detalle producto
        },
        modal_cancelar_pedido(){
            if(this.pedido.id_pedido){
                this.$refs.modalCancelarPedidoDirecto.toggle()
                return
            }

            this.$refs.modalCancelarPedido.toggle()
        },
        verChat(){
            let soloVisual = !!this.pedido.fecha_finalizado_chat
            this.$refs.modalChat.toggle(this.id_pedido, 5, soloVisual,1) // 5 = tipo pedido admin

        },
        async imprimir(){
            try {
                if (this.pedido.id_pedido == null){
                    const {data} = await PdfPedidos.generar_pdf_ticket_admin_pedidos({id_pedido: this.id_pedido})
                    const url = window.URL.createObjectURL(new Blob([data], {type:"application/pdf"}));
                    const pdfWindow = window.open(url);
                    pdfWindow.print();
                    return
                }
                this.$refs.imprimirRecibo.toggle(this.id_pedido, this.pedido.id_pedido);

            } catch (e){
                this.error_catch(e)
            }
        },
        open_calificaciones(){
            this.$refs.modalCalificacionesPedidoAdmin.toggle(this.id_pedido)
        },
        open_calificar_pedido(){
            this.$refs.modalCalificarPedido.toggle(this.id_pedido)
        },
        actualizarIconoChat(){
            this.$store.commit('pedidos/pedidos_historial_tendero/set_pedido', {...this.pedido, cant_mensajes: 0})
            // para actualizar el listado de pedidos
            let idx_pedido = this.pedidos.findIndex(e => e.id === this.id_pedido)

            if(idx_pedido >= 0){
                let found = this.pedidos[idx_pedido]
                found.cant_mensajes = 0
            }
        },
        modal_reclamar(){
            this.$refs.modalReclamo.toggle()
        }
    }
}
</script>

<style lang="scss" scoped>
.bg-rosa{
    background: #f5f5f5;
}
.text-orange{
    color: #FF612B;
}
.text-morado{
    color: #9508EB;
}
.alto{
    height: calc(100vh - 64px);
}
.bg-gr-prod{
    background: linear-gradient( to right, #03D6BC20, #0D4DFF20)
}
.container-cols{
    height: calc(100vh - 259px);
}
@media(max-width:1000px){
    .container-cols{
        height: calc(100vh - 298px) !important;
    }
}
</style>
